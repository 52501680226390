import BonusDetails from "../../../components/bonus-details";
import bombataImage from "../../../images/bonuses/bombata.jpg";
import cloverIcon from "../../../images/bonuses/bomb-icon.png";
import PageWrapper from "../../../components/page-wrapper";
import MiniBonus from "../../../components/mini-bonus";
import styles from "./index.module.css";
import restoImage from "../../../images/bonuses/resto.jpg";
import egyptImage from "../../../images/bonuses/egypt.jpg";
import moneyTrackingImage from "../../../images/bonuses/money-tracking.jpg";

const Bombata = () => {
  return (
    <PageWrapper>
      <BonusDetails
        imgUrl={bombataImage}
        imgAlt="scratch-banner"
        title="Бонус игра „Бомбата“"
        validIn="Важи за: всички зали Европа Бет"
        rulesTitle="Условия за участие в бонус играта:"
        icon={cloverIcon}
        iconAlt="cloverIcon"
        showIcon3="false"
        showIcon4="false"
        showIcon5="false"
        rule2="Бонус играта се провежда всяка събота от седмицата в часовия диапазон от 18:00ч. до 21:00ч."
        rule3="На случаен принцип по време на участие в играта всеки клиент може да спечели награда на стойност 10,00/десет/, 20,00/двадесет/, 30,00 /тридесет/ или 50,00 /петдесет/ лева"
        leftLinkName="<< БОНУС РЕСТО"
        leftLink="/resto"
        rightLinkName="ЕГИПЕТСКО СЪКРОВИЩЕ >>"
        rightLink="/egypt"
      />

      <div className={styles.shell}>
        <section className={styles.miniBonuses}>
          <MiniBonus
            imgUrl={restoImage}
            articleTitle="ПЕРСОНАЛНА БОНУС ИГРА „БОНУС РЕСТО“"
            address="Важи за: всички зали Европа Бет"
            pathName="/resto"
          />

          <MiniBonus
            imgUrl={egyptImage}
            articleTitle="персонална бонус игра „ЕГИПЕТСКО СЪКРОВИЩЕ“"
            address="Важи за: всички зали Европа Бет "
            pathName="/egypt"
          />

          <MiniBonus
            imgUrl={moneyTrackingImage}
            articleTitle="ПЕРСОНАЛНА БОНУС ИГРА „МЪНИ ТРАКИНГ“"
            address="Важи за: всички зали Европа Бет "
            pathName="/money-tracking"
          />
        </section>
      </div>
    </PageWrapper>
  );
};

export default Bombata;
