import React from "react";
import PageWrapper from "../../components/page-wrapper";
import styles from "./index.module.css";
import Background from "../../images/about-bg.jpg";
import GoogleMaps from "../../components/google-maps";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Text } from '../../containers/language'

const mainStyle = {
  backgroundImage: `linear-gradient(to left, rgba(32,16,26,0) 1%, rgba(0,0,0,1)), url(${Background})`,
  width: "100%",
  backgroundSize: "cover",
  paddingBottom: "2em",
};

const div = {
  width: "100%",
};

const About = () => {
  return (
    <PageWrapper>
      <main className={styles.main} style={mainStyle}>
        <section className={styles.about}>
          <article>
            <h2>за нас</h2>
            <p>
              <Text tid="about-intro"/>
            </p>

            <p>
              {" "}
              <Text tid="about-intro2"/>
             </p>

            <p>
              <Text tid="about-intro3"/>
            </p>

            <p>
            <Text tid="about-intro4"/>
            </p>

            <p>
            <Text tid="about-intro5"/>
            </p>
          </article>
        </section>

        <section className={styles.contacts}>
          <section className={styles.contactsInfo}>
            <div className={styles.title}>
              <h2><Text tid="contacts"/></h2>
            </div>

            <article className={styles.phonesEmail}>


            <div className={`${styles.iconArticle} ${styles.phonesWorkingTime}`}>
              <FontAwesomeIcon size="3x" icon="clock" color="whitesmoke" />

              <div className={styles.phones}>
                <p>Работно време за посочените телефони: <br/> понеделник - петък <br/>8:30 - 17:30 часа  </p>
    
              </div>
            </div>

            <div className={styles.iconArticle}>
              <FontAwesomeIcon size="3x" icon="phone" color="green" />

              <div className={styles.phones}>
                <a href="tel:+359884402050">0884 402 050</a>
                <a href="tel:+359898553132">0898 553 132</a>
              </div>

              <div className={styles.phonesWorkingTimeMobile}>
                <p>понеделник - петък</p>
                <p>8:30 - 17:30</p>
              </div>
            </div>


            <div className={styles.iconArticle}>
              <FontAwesomeIcon size="3x" icon="envelope" color="#f3b359" />
              <div className={styles.email}>
                <a href="mailto:europabet.bg@gmail.com">europabet.bg@gmail.com</a>
              </div>
            </div>
            </article>

            <div className={styles.iconArticle}>
              <FontAwesomeIcon
                size="3x"
                icon="map-marker-alt"
                color="#c2185b"
              />
              <div className={styles.address}>
                <span>EUROPABET Възраждане</span>
                <span>Варна, ул."Владимир Висоцки" 2</span>
                <a rel="noreferrer" target="_blank" href="https://facebook.com/europabet.vazrazhdane">
                  facebook.com/europabet.vazrazhdane
                </a>
              </div>
            </div>

            <div className={styles.iconArticle}>
              <FontAwesomeIcon
                size="3x"
                icon="map-marker-alt"
                color="#c2185b"
              />
              <div className={styles.address}>
                <span>EUROPABET Владислав</span>
                <span>Варна, бул."Владислав Варненчик" 130</span>
                <span>
                  <a rel="noreferrer" target="_blank" href="https://bg-bg.facebook.com/%D0%95%D0%B2%D1%80%D0%BE%D0%BF%D0%B0-%D0%91%D0%B5%D1%82-%D0%92%D0%BB%D0%B0%D0%B4%D0%B8%D1%81%D0%BB%D0%B0%D0%B2-101602235504672/">
                    facebook.com/europabet.vladislav
                  </a>
                </span>
              </div>
            </div>

            <div className={styles.iconArticle}>
              <FontAwesomeIcon
                size="3x"
                icon="map-marker-alt"
                color="#c2185b"
              />
              <div className={`${styles.address}`}>
                <span>EUROPABET Ботевата</span>
                <span>Варна, ул."Фр. Жулио Кюри" 48</span>
                <a rel="noreferrer" target="_blank" href="https://facebook.com/casinoeuropebet">
                  facebook.com/casinoeuropebet
                </a>
              </div>
            </div>
          </section>

          <div className={styles.mapWrapper}>
            <GoogleMaps
              title="gambling-halls"
              googleUrl="https://www.google.com/maps/d/embed?mid=1JZgk3_TMybs_MXd5F06_TO2vbuyfhb7v"
              width="1025"
              height="700"
              loading="lazy"
            />
          </div>
        </section>
      </main>
    </PageWrapper>
  );
};

export default About;
