import BonusDetails from "../../../components/bonus-details";
import egyptImage from "../../../images/egypt_banner_new.jpg";
import tresureIcon from "../../../images/bonuses/egypt-icon.jpg";
import PageWrapper from "../../../components/page-wrapper";
import styles from "./index.module.css";
import restoImage from "../../../images/bonuses/resto.jpg";
import moneyTrackingImage from "../../../images/bonuses/money-tracking.jpg";
import MiniBonus from "../../../components/mini-bonus";
import bombataImage from "../../../images/bonuses/bombata.jpg";

const Egypt = () => {
  return (
    <PageWrapper>
      <BonusDetails
        imgUrl={egyptImage}
        imgAlt="egypt-banner"
        title="Ежедневна персонална бонус игра „ЕГИПЕТСКО СЪКРОВИЩЕ“"
        validIn="Важи за: всички зали Европа Бет "
        rulesTitle="Право на участие имат всички клиенти притежаващи индивидуални RFID(чип) карти и изиграли сума над 30 /тридесет/ лева на игралните автомати."
        icon={tresureIcon}
        iconAlt="treasureIcon"
        showIcon3="false"
        showIcon4="false"
        showIcon5="false"
        rule2="При достигане на условията  за участие в бонус играта, клиента получава правото да избира между три на брой сандъка, в които са скрити печалби от 10 /десет/, 15 /петнадесет и 30 /тридесет/ лева. Спечелената премия влиза в кредита на игралния автомат и може да бъде изплатена без да се налага да се залага."
        rule3="Всеки участник в игрите има право на едно участие в рамките на 24 часа."
        leftLinkName="<< БОМБАТА"
        leftLink="/bombata"
        rightLinkName="МЪНИ ТРАКИНГ >>"
        rightLink="/money-tracking"
      />

      <div className={styles.shell}>
      <section className={styles.miniBonuses}>
          <MiniBonus
            imgUrl={restoImage}
            articleTitle="ПЕРСОНАЛНА БОНУС ИГРА „БОНУС РЕСТО“"
            address="Важи за: всички зали Европа Бет"
            pathName="/resto"
          />

            <MiniBonus
            imgUrl={bombataImage}
            articleTitle="БОНУС ИГРА „БОМБАТА“"
            address="Важи за: всички зали Европа Бет"
            pathName="/bombata"
          />

          <MiniBonus
            imgUrl={moneyTrackingImage}
            articleTitle="ПЕРСОНАЛНА БОНУС ИГРА „МЪНИ ТРАКИНГ“"
            address="Важи за: всички зали Европа Бет "
            pathName="/money-tracking"
          />
        </section>
      </div>
    </PageWrapper>
  );
};

export default Egypt;
