import styles from './index.module.css';
import PageWrapper from '../../components/page-wrapper';
import Background from "../../images/bonuses-bg.jpg";
import SingleBonus from '../../components/bonus-article';
import restoImage from '../../images/bonuses/resto.jpg';
import egyptImage from '../../images/egypt_banner_new.jpg';
import moneyTrackImage from '../../images/bonuses/money-tracking.jpg';
import bombataImage from '../../images/bonuses/bombata.jpg'


const background = {
  backgroundImage: `url(${Background})`,
  width: "100%",
  backgroundSize: "cover repeat",
  padding: "40px 0",
  textAlign: "center",
  borderBottom: "2px solid #114780",
};

const Bonuses = () => {
  return <PageWrapper>
     <div style={background}>
       <div className={styles.pageWrapper}>

      <SingleBonus imgUrl={restoImage} articleTitle='Персонална бонус игра „БОНУС РЕСТО“'
       address='Важи за: всички зали Европа Бет'
       pathName='/resto'
       />

      <SingleBonus imgUrl={bombataImage} articleTitle='Бонус игра „БОМБАТА“'
       address='Важи за: всички зали Европа Бет'
       pathName='/bombata'
       />

      <SingleBonus imgUrl={egyptImage} articleTitle='Ежедневна персонална бонус игра „ЕГИПЕТСКО СЪКРОВИЩЕ“'
       address='Важи за: всички зали Европа Бет'
       pathName='/egypt'
       />
       <SingleBonus imgUrl={moneyTrackImage} articleTitle='Персонална бонус игра „МЪНИ ТРАКИНГ“'
       address='Важи за: всички зали Европа Бет'
       pathName='/money-tracking'
       
/>
       </div>
     </div>
  </PageWrapper>
}

export default Bonuses;
